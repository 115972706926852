<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12">
        <b-tabs pills>
          <!-- View Generated Reports -->
          <b-tab
            active
            title="View Generated Reports"
          >
            <reports-table />
          </b-tab>
          <!--/ View Generated Reports tab -->

          <!-- Generate a Report -->
          <b-tab
            title="Generate a Report"
          >
            <generate-report-form />
          </b-tab>
          <!--/ Generate a Report tab -->
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import GenerateReportForm from './GenerateReportForm.vue'
import ReportsTable from './ReportsTable.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    GenerateReportForm,
    ReportsTable,
  },
  data() {
    return {
      options: {},
      hideInformation: true,
      hideSocial: true,
      hideNotifications: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  // beforeCreate() {
  //   this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  // },
  metaInfo() {
    return { title: this.$t('page_titles.reports'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>
