var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"generateReportForm"},[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":_vm.overlayVariant,"opacity":_vm.overlayOpacity,"rounded":"sm"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveChanges($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[(_vm.$can('toggle_office_user', 'Report'))?_c('b-form-group',{attrs:{"label":"Select Report Type","label-for":"report-type"}},[_c('b-form-radio-group',{attrs:{"id":"report-type","options":_vm.radioOptions,"name":"report-type"},on:{"input":_vm.selectionChange},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),(_vm.selected === 'Office' && _vm.$can('toggle_office_user', 'Report'))?_c('b-form-group',{attrs:{"label":"Select Office","label-for":"office-select"}},[_c('validation-provider',{attrs:{"name":"Office","vid":"office-select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"office-select","close-on-select":true,"clearable":false,"name":"office-select","label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"options":_vm.filteredOffices},model:{value:(_vm.office),callback:function ($$v) {_vm.office=$$v},expression:"office"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2210438415)})],1):_vm._e(),(_vm.selected === 'User' && _vm.$can('get_users_reports', 'Report'))?_c('b-form-group',{attrs:{"label":"Select Users","label-for":"user-select"}},[_c('validation-provider',{attrs:{"name":"User","vid":"user-select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"user-select","close-on-select":true,"clearable":false,"name":"user-select","label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"options":_vm.filteredUsers},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,395713967)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Select Start Date","label-for":"start-date"}},[_c('validation-provider',{attrs:{"name":"Start Date","vid":"start-date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"config":{ dateFormat: 'Z', altInput: true, altFormat: 'F j, Y', enableTime: false, maxDate: _vm.maxDate },"state":errors.length > 0 ? false:null,"name":"start-date","data-vv-validate-on":"blur"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Select End Date","label-for":"end-date"}},[_c('validation-provider',{attrs:{"name":"End Date","vid":"end-date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"config":{ dateFormat: 'Z', altInput: true, altFormat: 'F j, Y', enableTime: false, maxDate: _vm.maxDate },"state":errors.length > 0 ? false:null,"name":"end-date","data-vv-validate-on":"blur"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('buttons.generate_report'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 ml-25",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.reset'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }