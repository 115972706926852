<template>
  <b-card>
    <validation-observer
      ref="generateReportForm"
    >
      <b-overlay
        :show="loading"
        :variant="overlayVariant"
        :opacity="overlayOpacity"
        rounded="sm"
      >
        <!-- form -->
        <b-form @submit.prevent="saveChanges">
          <b-row>
            <b-col
              cols="12"
            >
              <div>
                <b-form-group
                  v-if="$can('toggle_office_user', 'Report')"
                  label="Select Report Type"
                  label-for="report-type"
                >
                  <b-form-radio-group
                    id="report-type"
                    v-model="selected"
                    :options="radioOptions"
                    name="report-type"
                    @input="selectionChange"
                  />
                </b-form-group>

                <b-form-group
                  v-if="selected === 'Office' && $can('toggle_office_user', 'Report')"
                  label="Select Office"
                  label-for="office-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Office"
                    vid="office-select"
                    rules="required"
                  >
                    <v-select
                      id="office-select"
                      v-model="office"
                      :close-on-select="true"
                      :clearable="false"
                      name="office-select"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      :options="filteredOffices"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="selected === 'User' && $can('get_users_reports', 'Report')"
                  label="Select Users"
                  label-for="user-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="User"
                    vid="user-select"
                    rules="required"
                  >
                    <v-select
                      id="user-select"
                      v-model="user"
                      :close-on-select="true"
                      :clearable="false"
                      name="user-select"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      :options="filteredUsers"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Select Start Date"
                  label-for="start-date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Start Date"
                    vid="start-date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="startDate"
                      :config="{ dateFormat: 'Z', altInput: true, altFormat: 'F j, Y', enableTime: false, maxDate: maxDate }"
                      :state="errors.length > 0 ? false:null"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="form-control"
                      name="start-date"
                      data-vv-validate-on="blur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Select End Date"
                  label-for="end-date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="End Date"
                    vid="end-date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="endDate"
                      :config="{ dateFormat: 'Z', altInput: true, altFormat: 'F j, Y', enableTime: false, maxDate: maxDate }"
                      :state="errors.length > 0 ? false:null"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="form-control"
                      name="end-date"
                      data-vv-validate-on="blur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- <b-form-group
                  v-if="existingQuestion && existingQuestion.question.input_type && (existingQuestion.question.input_type.name === 'single-select' || existingQuestion.question.input_type.name === 'multi-select-dropdown' || existingQuestion.question.input_type.name === 'multi-select-modal')"
                  label="Select Option Group"
                  label-for="option-group-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Option Group"
                    vid="option-group-select"
                    rules="required"
                  >
                    <v-select
                      id="option-group-select"
                      v-model="existingQuestion.question.option_group"
                      :close-on-select="true"
                      :clearable="false"
                      name="option-group-select"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      :options="optionGroups"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->
              </div>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                class="mt-1 mr-1"
              >
                {{ $t('buttons.generate_report') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mt-1 ml-25"
                variant="outline-secondary"
                @click.prevent="resetForm"
              >
                {{ $t('buttons.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAlert, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner, BFormRadioGroup, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  heightFade,
} from '@core/directives/animations'
import {
  required,
  regex,
} from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    ToastificationContent,
    vSelect,
    BOverlay,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      required,
      regex,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      selected: 'Office',
      radioOptions: [
        { text: 'Office', value: 'Office' },
        { text: 'User', value: 'User' },
      ],
      office: null,
      user: null,
      startDate: null,
      endDate: null,
      showReportPopup: false,
      reportPopupTitle: 'Report',
      reportURL: null,
      // Filter Options
      startDateFilter: null,
      endDateFilter: null,
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('office', ['offices']),
    ...mapGetters('user', ['users']),
    ...mapGetters('report', ['reportTypes']),
    filteredUsers() {
      if (this.activeUser.currentRole.name !== 'admin') {
        return this.users.filter(u => u.office_id === this.activeUser.office_id)
      }
      return this.users.filter(u => u.office_id !== null)
    },
    filteredOffices() {
      if (this.activeUser.currentRole.name !== 'admin') {
        return this.offices.filter(o => o.id === this.activeUser.office_id)
      }
      return this.offices
    },
    maxDate() {
      return Date.now()
    },
  },
  async created() {
    await this.getOffices()
    await this.getUsers()
    await this.getReportTypes()
  },
  methods: {
    ...mapActions('user', ['getUsers']),
    ...mapActions('office', ['getOffices']),
    ...mapActions('report', ['getReportTypes', 'createReport']),
    async resetForm() {
      this.office = null
      this.user = null
      this.startDate = null
      this.endDate = null
      this.$refs.generateReportForm.reset()
    },
    saveChanges() {
      this.$refs.generateReportForm.validate().then(async success => {
        if (success) {
          this.serverError = null
          this.loading = true
          try {
            if (!(this.$can('toggle_office_user', 'Report'))) {
              this.selected = 'User'
            }
            const payload = {}
            const reportType = this.reportTypes.find(rt => rt.name === this.selected)
            payload.report_type_id = reportType.id
            payload.user_id = null
            payload.office_id = null

            if (!this.user) {
              this.user = this.activeUser
            }

            if (this.user && reportType.name === 'User') {
              payload.user_id = this.user.id
              payload.office_id = this.user.office_id
              payload.subtype = this.activeUser.currentRole.name === 'admin' ? this.user.default_role.name : this.activeUser.currentRole.name
            }
            if (this.office && reportType.name === 'Office') {
              payload.office_id = this.office.id
            }
            payload.start_date = this.startDate
            payload.end_date = this.endDate
            this.reportPopupTitle = `${reportType.name} Report - ${moment(this.startDate).format('L')}-${moment(this.endDate).format('L')}`
            const response = await this.createReport(payload)
            this.reportURL = `${process.env.VUE_APP_API_HOST}/api/reports/${response.data.uuid}?format=html`
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Report generated successfully',
              },
            })
            this.resetForm()
            this.$router.push(`/reports/${response.data.uuid}`)
          } catch (e) {
            console.log(`Error in saveChanges() in GenerateReportForm.vue: ${e.message}`)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: 'Could not generate report',
              },
            })
          } finally {
            this.loading = false
          }
        }
      })
    },
    async selectionChange() {
      await this.resetForm()
    },
    async setExistingQuestion(value) {
      try {
        if (this.loggableItemPreview.question_orders.length > 0) {
          const clone = JSON.parse(JSON.stringify(this.loggableItemPreview)) // deep clone to avoid mutating on the vuex getter directly
          const index = clone.question_orders.findIndex(q => q.id === this.existingQuestion.id)
          clone.question_orders[index] = this.existingQuestion
          if (value) {
            // update preview item
            await this.setLoggableItemPreview(clone)
          }
        }
      } catch (e) {
        console.debug(`Error in setExistingQuestion in EditQuestion.vue: ${e.message}`)
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
