<template>
  <b-card>
    <b-row
      v-if="showSearch"
    >
      <b-col
        v-if="$can('toggle_office_user', 'Report')"
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Filter by Report Type"
          label-for="report-type-select"
        >
          <v-select
            v-model="reportTypeFilter"
            :options="reportTypeOptions"
            :clearable="false"
            name="report-type-select"
          />
        </b-form-group>
      </b-col>

      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Search"
          label-for="filter-input"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              name="filter-input"
              type="search"
              placeholder="Type to Search"
            />

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="showSearch && activeUser.currentRole.name === 'admin'"
    >
      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Filter by Office"
          label-for="office-select"
        >
          <v-select
            v-model="officeFilter"
            :options="officeOptions"
            :clearable="false"
            name="office-select"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      :fields="fields"
      :items="filteredReports"
      :responsive="true"
      striped
      bordered
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="mt-2"
      @filtered="onFiltered"
    >
      <template #cell(report_type)="data">
        <span v-if="data.item.report_type">{{ data.item.report_type.name }} Report</span>
        <span v-else>N/A</span>
      </template>
      <template #cell(user)="data">
        <span v-if="data.item.user">{{ data.item.user.name }}</span>
        <span v-else>N/A</span>
      </template>
      <template #cell(office)="data">
        <span v-if="data.item.office">{{ data.item.office.name }}</span>
        <span v-else-if="data.item.user.office">{{ data.item.user.office.name }}</span>
        <span v-else>N/A</span>
      </template>
      <template #cell(period)="data">
        {{ data.item.start_date | moment('ll') }} - {{ data.item.end_date | moment('ll') }}
      </template>
      <template #cell(role)="data">
        {{ findRole(data.item.role_id) ? findRole(data.item.role_id).description : null }}
      </template>
      <template #cell(created_at)="data">
        {{ data.value | moment('LLL') }}
      </template>

      <template #cell(actions)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top="$t('buttons.view_report')"
          variant="primary"
          class="btn-icon m-1"
          :to="{ name: 'reports-view', params: { reportUuid: data.item.uuid}}"
          target="_blank"
        >
          <feather-icon icon="EyeIcon" />
          {{ $t('buttons.view_report') }}
        </b-button>
        <b-button
          v-if="$can('delete', getReportSubject(data.item))"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top="$t('buttons.delete_report')"
          variant="danger"
          class="btn-icon m-1"
          @click="deleteGeneratedReport(data.item.uuid)"
        >
          <feather-icon icon="TrashIcon" />
          {{ $t('buttons.delete_report') }}
        </b-button>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="m=1"
          :to="{ name: 'offices-view', params: { officeId: data.item.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('buttons.view') }}</span>
        </b-button> -->
      </template>

      <template #cell(generated_by)="data">
        <b-badge
          pill
          :variant="`primary`"
          class="text-capitalize"
        >
          <span v-if="data.item.generated_by === 'user'">Me</span>
          <span v-else>{{ data.item.generated_by }}</span>
        </b-badge>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
    <p
      v-if="filteredReports.length == 0"
      class="text-center"
    >
      {{ $t('no_data') }}
    </p>
    <b-col
      cols="12"
    >
      <b-pagination
        v-show="filteredReports.length > 0"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-card>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BTable,
  BPagination,
  BBadge,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { subject } from '@casl/ability'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BTable,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      // eslint-disable-next-line no-param-reassign
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'report_type', label: 'Report Type', sortable: true },
        { key: 'user', label: 'User', sortable: true },
        { key: 'office', label: 'Office', sortable: true },
        { key: 'Period', label: 'Period', sortable: true },
        { key: 'created_at', label: 'Date Generated', sortable: true },
        { key: 'generated_by', label: 'Generated By', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      perPage: 10,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      filter: null,
      totalRows: 1,
      officeFilter: { label: 'All', value: 'all' },
      reportTypeFilter: { label: 'All', value: 'all' },
    }
  },
  computed: {
    /* eslint-disable no-plusplus */
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('report', ['reportTypes', 'reports']),
    ...mapGetters('office', ['offices']),
    reportTypeOptions() {
      const options = [{
        label: 'All',
        value: 'all',
      }]
      try {
        for (let i = 0; i < this.reportTypes.length; i++) {
          const reportType = {}
          reportType.label = this.reportTypes[i].name
          reportType.value = this.reportTypes[i].id
          options.push(reportType)
        }
      } catch (e) {
        // do nothing
      }
      return options
    },
    officeName() {
      let name = null
      if (this.activeUser && this.activeUser.office) {
        name = this.activeUser.office.name
      }
      return name
    },
    officeOptions() {
      const officeOptions = [{
        label: 'All',
        value: 'all',
      }]
      for (let i = 0; i < this.offices.length; i++) {
        const office = {}
        office.label = this.offices[i].name
        office.value = this.offices[i].id
        officeOptions.push(office)
      }
      return officeOptions
    },
    filteredReports() {
      try {
        if (this.officeFilter) {
          if (this.officeFilter.value === 'all' && this.reportTypeFilter.value === 'all') {
            return this.reports
          }
          if (this.officeFilter.value === 'all' && this.reportTypeFilter.value !== 'all') {
            return this.reports.filter(r => r.report_type_id === this.reportTypeFilter.value)
          }
          if (this.officeFilter.value !== 'all' && this.reportTypeFilter.value === 'all') {
            return this.reports.filter(r => r.office_id === this.officeFilter.value)
          }
          return this.reports.filter(r => r.report_type_id === this.reportTypeFilter.value && r.office_id === this.officeFilter.value)
        }
        if (this.reportTypeFilter.value !== 'all') {
          return this.reports.filter(r => r.report_type_id === this.reportTypeFilter.value)
        }
        return this.reports
      } catch (e) {
        console.warn('Could not apply filter')
        return this.reports
      }
    },
  },
  async created() {
    try {
      if (this.activeUser && this.activeUser.role && this.activeUser.currentRole.name === 'admin') {
        await this.getOffices()
      }
      await this.getReports()
      await this.getReportTypes()
    } catch (e) {
      console.log(e.message)
      console.debug(`Error in created() of UsersTable.vue: ${e.message}`)
    }
  },
  mounted() {
    this.totalRows = this.filteredReports.length
  },
  methods: {
    ...mapActions('report', ['getReports', 'getReportTypes', 'deleteReport']),
    ...mapActions('office', ['getOffices']),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onSuccess(event) {
      this.$emit('success', event)
    },
    onError(event) {
      this.$emit('error', event)
    },
    getReportSubject(item) {
      const reportSubject = subject('Report', {
        ...item,
      })
      return reportSubject
    },
    viewReport(uuid) {
      try {
        this.$router.push({ name: 'reports-view', params: { reportUuid: uuid } })
      } catch (e) {
        console.debug(`Error in viewReport: ${e.message}`)
      }
    },
    deleteGeneratedReport(uuid) {
      try {
        this.$swal({
          title: 'Delete Report',
          text: 'Please confirm you would like to delete this generated report.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete report',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            // eslint-disable-next-line no-useless-catch
            try {
              await this.deleteReport(uuid)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'Report was deleted successfully',
                },
              }, 5000)
            } catch (e) {
              throw e
            }
          }
        }).catch(e => {
          throw e
        })
      } catch (e) {
        console.debug(`Error in deleteGeneratedReport: ${e.message}`)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Oops! Something went wrong when trying to delete the report. Please try again.',
          },
        }, 5000)
      }
    },
    /*
    async resetPassword(id) {
      try {
        const response = await this.getUser(id)
        const user = response.data
        this.$swal({
          title: 'Reset Password',
          text: 'Please confirm you would like to reset password for this user. An email with instructions will be sent to this user.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, reset password',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            // eslint-disable-next-line no-useless-catch
            try {
              await this.recoverPassword({ email: user.email })
              this.$emit('success', { message: 'Password reset instructions sent' })
            } catch (e) {
              throw e
            }
          }
        }).catch(e => {
          throw e
        })
      } catch {
        console.debug(`Cannot fetch user with id: ${id}`)
        this.$emit('error', { message: 'Could not reset password' })
      }
    },
    */
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
